<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/customer">Customer</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >Manage Customer</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Customer</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_customer')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
                $store.dispatch('modalWithMode', {
                  id: 'newCustomer',
                  mode: 'create',
                })
              "
        >New Customer</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-9 d-block mr-0 pr-0">
          <select v-model="params.size" @change="searchFilter" id="customer_filter_size" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
              <i class="fas fa-download txt-white" @click="download" title="Download Excel"></i>
        </div>
        <div class="col-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            @keyup="searchFilter"
            class="float-right"
            id="customer_search"
            placeholder="Search..."
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Customer ID</th>
              <th class="wd-20">Customer Name</th>
              <th class="wd-20p">Address</th>
              <th class="wd-10p" v-if="authData.general_setting.enable_vehicle_detail==1">Vehicle Details</th>
              <th class="wd-10p" v-else>Email</th>
              <th class="wd-10p">Contact No.</th>
              <th class="wd-10p">Due Amt.</th>
              <th
                class="wd-15p text-center table-end-item no-print"
                v-if="checkIfAnyPermission(['view_customer_profile','edit_customer','create_pay_in','delete_customer'])
"
              >Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && customerLists.length > 0">
            <tr v-for="(customer,index) in customerLists" :key="index">
              <th scope="row" class="table-start-item">{{pageSerialNo+index}}.</th>
              <td>{{customer.customer_id}}</td>
              <td>{{customer.name}}</td>
              <td>{{customer.address}}</td>
              <td v-if="authData.general_setting.enable_vehicle_detail==1">
                <span v-if="customer.vehicle_detail">Engine No. - {{customer.vehicle_detail.engine_no}}</span><br>
                <span v-if="customer.vehicle_detail">Chassis No. - {{customer.vehicle_detail.chasis_no}}</span><br>
                <span v-if="customer.vehicle_detail">Vehicle No. - {{customer.vehicle_detail.vehicle_no}}</span>
              </td>
              <td v-else>{{customer.email}}</td>
              <td>{{customer.phone}}</td>
              <td>{{customer.closing_due}}</td>
              <td
                class="text-right table-end-item no-print"
                v-if="checkIfAnyPermission(['view_customer_profile','edit_customer','create_pay_in','delete_customer'])
"
              >
                <a
                  href="javascript:;"
                  v-if="checkSinglePermission('create_pay_in')"
                  class="mr-3"
                  title="Receipt Voucher"
                  :id="'customerReceipt'+customer.id"
                >
                  <i class="fas fa-receipt" @click="view('payIn', customer.id)"></i>
                </a>
                <a
                  href="javascript:;"
                  v-if="checkSinglePermission('view_customer_profile')"
                  class="mr-3"
                  :id="'customerView'+customer.id"
                  title="View Profile"
                >
                  <i
                    class="fas fa-eye tx-success"
                    @click="$router.push(`/customer/profile/${customer.id}`)"
                  ></i>
                </a>
                <a
                  href="javascript:;"
                  v-if="checkSinglePermission('edit_customer')"
                  class="mr-3"
                  :id="'customerEdit'+customer.id"
                  @click="edit('editCustomer',customer.id)"
                  title="Edit Customer"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  v-if="checkSinglePermission('delete_customer')"
                  :id="'customerDelete'+customer.id"
                  @click="drop(customer.id)"
                  title="Delete Customer"
                >
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && customerLists.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td colspan="11" style="text-align:center;">{{error_message}}</td>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <CustomerCreate @store-customer="getData()" />
        <EditCustomer @parent-event="getData()" />
        <PayIn @parent-event="getData()" />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import CustomerCreate from "./NewCustomer";
import PayIn from "./pay-in";
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import EditCustomer from "./EditCustomer.vue";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  data() {
    return {
      loading: true,
      page: 1,
      error: false,
      params: {
        size: "10",
        searched: "",
        offset: 0,
      },
      totalPageCount: 0,
      error_message:'',
      pageSerialNo: 1,
    };
  },
  components: { CustomerCreate, PayIn, Paginate, EditCustomer },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "authData",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("customers", ["customerLists"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    drop(id) {
      this.$swal({
        title: "Do you really want to delete Customer ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // delete lead
         return Services.deleteCustomer(id)
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
              this.getData();
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    getData() {
      this.loading = true;
      Services.getCustomerFilteredDatas(this.params)
        .then((res) => {
          this.$store.commit("customers/setCustomerLists", res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
        });
      //this.$store.commit("getData", `api/customers/size/${this.filter.size}`);
    },
    searchFilter: _.debounce(function () {
      this.loading = true;
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      Services.getCustomerFilteredDatas(this.params)
        .then((res) => {
          this.$store.commit("customers/setCustomerLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }

          let error = err.response.data.error_message;
          this.setNotification(error);
        });
    }, 1000),
    setNotification(msg) {
      console.log(msg);
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    download(){
      Services.getCustomerExcel()
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const elink = document.createElement("a");
          elink.download = "customer-report.xlsx";
          elink.style.display = "none";
          elink.style.font = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  watch: {
    // eventMessage(value) {
    //   if (value.indexOf("deleted success") >= 0) {
    //     this.$store.commit("setRemoveList", this.dataId);
    //   }else if(value.indexOf("Pay in added") >= 0){
    //     this.getData();
    //   }
    // },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
